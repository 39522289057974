import Apiservice from '@/core/services/api.service';
import { API_URL } from '@/common/config.js'
const apiService = Apiservice;


export default class StateService {

      #api;
      #cityApi;
      constructor(){
            this.#api = `${API_URL}user/country/`
            this.#cityApi = `${API_URL}user/state`

      }
      all(country, state){
            let url  = `${this.#api}${country}/state/${state}/city`;
            return apiService.get(url);
      }

      paginate(countryId, page = 1) {
            let url = `${this.#cityApi}/${countryId}/city`;
            if (page)
                  url = url + '?page=' + page;
            return apiService.get(url);
      }

      update(countryId, id, data) {
            let url = `${this.#cityApi}/${countryId}/city/${id}`
            return apiService.put(url, data);

      }

      store(countryId, data) {
            let url = `${this.#cityApi}/${countryId}/city`
            return apiService.post(url, data);

      }

      show(countryId, id) {
            let url = `${this.#cityApi}/${countryId}/city/${id}`
            return apiService.get(url);
      }

      delete(countryId, id) {
            let url = `${this.#cityApi}/${countryId}/city/${id}`
            return apiService.delete(url);
      }
}
