<template>
  <div>
    <b-modal id="modal-scrollable" size="md" ref="my-modal" hide-footer title="Add/Edit City">
      <div class="row">
        <div class="col-12 form-group">
          <input type="text" class="form-control" v-model="city.title" placeholder="Title" />
          <span class="text-danger" v-if="$v.city.title.$error">Title is required</span>
        </div>
        <div class="col-6 form-group">
         <label for="">Is Active</label>
          <b-form-checkbox
            v-model="city.is_active"
            name="check-button"
            class
            switch
          >Status</b-form-checkbox>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <v-btn
                  class="text-primary"
                  depressed
                  block
                  @click="resetForm"
          >Cancel
          </v-btn>

        </div>
        <div class="col-6">
          <v-btn
                  class="text-primary btn btn-primary"
                  depressed
                  block
                  @click="createOrUpdate"
                  :loading="isBusy"
          >Save
          </v-btn>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";

import CityService from "@/core/services/address/CityService";
const cityService = new CityService();

export default {
      props:["state_id"],
  validations: {
    city: {
      title: { required }
    }
  },
  data() {
    return {
      edit: false,
      isBusy: false,
      city: {
        title: null
      }
    };
  },
  methods: {
    showModal(id = null) {
      if (id) {
        this.edit = true;
        this.getCity(id);
      }
      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    toggleModal() {
      this.$refs["my-modal"].toggle("#toggle-btn");
    },
    getCity(id) {
      cityService.show(this.state_id, id).then(response => {
        this.city = response.data.city;
      });
    },

    createOrUpdate() {
      this.$v.city.$touch();
      if (this.$v.city.$error) {
        setTimeout(() => {
          this.$v.city.$reset();
        }, 3000);
      } else {
        if (this.edit) {
          this.updateCity();
        } else {
          this.createCity();
        }
      }
    },
    updateCity() {
      cityService
        .update(this.state_id, this.city.id, this.city)
        .then(response => {
          this.isBusy = false;
          this.resetForm();
        })
        .catch(error => {
          this.isBusy = false;
        });
    },
    createCity() {
      cityService
        .store(this.state_id, this.city)
        .then(response => {
          this.isBusy = false;
          this.resetForm();
        })
        .catch(error => {
          this.isBusy = false;
        });
    },
    resetForm() {
      this.edit = false;
      this.city= {
        title: null
      }
       this.$v.city.$reset();
      this.hideModal();
      this.$emit("refresh_state");
    }
  }
};
</script>

