<template>
    <v-app>
        <div class="card card-custom">
            <div class="card-body p-0">
                <div
                        class="wizard wizard-2"
                        id="kt_wizard_v2"
                        data-wizard-city="step-first"
                        data-wizard-clickable="true"
                >
                    <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
                        <!--begin: Wizard Form-->

                        <div class="card">
                            <div class="card-body">
                                <div class="card-title">
                                    <div class="row">
                                        <div class="col-6">
                                            <h4>City</h4>
                                        </div>
                                        <div class="col-6 text-right">
                                            <button class="btn btn-primary" @click="addcity">Add city</button>
                                        </div>
                                    </div>

                                    <div class="col-12">
                                        <div class="">
                                            <table class="table">
                                                <thead>
                                                <th>Name</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                                </thead>
                                                <tbody>
                                                <tr v-for="(city, index) of cities" :key="index">
                                                    <td>{{city.title}}</td>
                                                    <td>
                                                        <i v-if="city.is_active" class="fas fa-check text-success"></i>
                                                        <i v-else class="fas fa-ban text-danger"></i>
                                                    </td>
                                                    <td>
                            <span class="cursor-pointer" @click="editCity(city)">
                              <i class="fas fa-edit"></i>
                            </span>
                                                        <span class="cursor-pointer" @click="deletecity(city.id)">
                              <i class="ml-3 fas fa-trash"></i>
                            </span>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <b-pagination
                                                v-model="currentPage"
                                                :total-rows="rows"
                                                :per-page="perPage"
                                                aria-controls="my-table"
                                                @change="getCities"
                                        ></b-pagination>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <create-and-update :state_id="state_id" ref="city" @refresh_state="getCities"></create-and-update>
    </v-app>
</template>
<script>
    import CityService from "@/core/services/address/CityService";
    import CreateAndUpdate from "./CreateAndUpdate";

    const cityService = new CityService();

    export default {
        components: {
            CreateAndUpdate
        },
        data() {
            return {
                cities: [],
                perPage: 25,
                currentPage: 1,
                rows: 0
            };
        }, computed: {
            state_id() {
                return this.$route.params.id;
            }
        },
        mounted() {
            this.getCities();
        },
        methods: {
            getCities() {
                cityService.paginate(this.state_id, this.currentPage).then(response => {
                    this.cities = response.data.data;
                    //   this.currentPage = response.data.meta.current_page
                    this.perPage = response.data.meta.per_page;
                    this.rows =
                        parseInt(response.data.meta.last_page) * parseInt(this.perPage);
                });
            },
            deleteCity(id) {
                cityService.delete(this.state_id, id).then(response => {
                    this.Cetcity();
                });
            }, editCity(city) {
                this.$refs['city'].showModal(city.id)
            }, addcity() {
                this.$refs['city'].showModal()
            }
        }
    };
</script>
